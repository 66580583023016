<template>
  <default-layout :searchFunction="loadData" searchParamString="q">
    <v-container fluid tag="section">
      <page-loading :show="isLoading" />
      <v-alert type="error" :value="showAlert" dismissible>
        <div v-html="messageAlert" />
      </v-alert>
      <coin-usage-list
        :items="coinUsages"
        :meta="meta"
        :permissions="permissions"
        @changePage="changePage"
      ></coin-usage-list>
    </v-container>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
const PageLoading = () => import('@/components/content-loading/page-loading.vue');
const CoinUsageList = () => import('@/components/transaction-management/coin-usage/list');
import { mapState } from 'vuex';

export default {
  components: {
    DefaultLayout,
    PageLoading,
    CoinUsageList,
  },
  data() {
    return {
      isLoading: false,
      showAlert: false,
      messageAlert: null,
      minDateFilter: null,
      maxDateFilter: null,
      statusFilter: null,
      typeFilter: null,
      salesFilter: null,
    };
  },
  computed: {
    ...mapState({
      permissions: (state) => state.coinUsage.permissions,
      coinUsages: (state) => state.coinUsage.coinUsages,
      meta: (state) => state.coinUsage.meta,
    }),
  },
  async mounted() {
    try {
      this.isLoading = true;
      let query = this.$route.query;
      this.$store.commit('coinUsage/RESET_DEFAULT');
      const response = await this.$store.dispatch('coinUsage/getInitData', query);
      console.log('CHECK COIN USAGE INIT DATA: ', response);
    } catch (e) {
      this.showAlert = true;
      this.messageAlert = e;
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    async loadData(query) {
      try {
        this.isLoading = true;
        const response = await this.$store.dispatch('coinUsage/getData', query);
        console.log('GET COIN USAGE DATA: ', response);
      } catch (e) {
        this.showAlert = true;
        this.messageAlert = e;
      } finally {
        this.isLoading = false;
      }
    },
    // eslint-disable-next-line no-unused-vars
    async changePage(page) {
      let query = this.$route.query;
      query.page = page;
      await this.loadData(query);
    },
  },
};
</script>
